import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { counts } from '../utils/data';
import CountsCard from '../components/cards/CountsCard';
import WeeklyStatCard from '../components/cards/WeeklyStatCard';
import CategoryChart from '../components/cards/CategoryChart';
import AddWorkout from '../components/AddWorkout';
import WorkoutCard from '../components/cards/WorkoutCard';
import NutritionsCard from '../components/cards/NutritionsCard';

import { CircularProgress } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers';
import { addWorkout, getDashboardDetails, getWorkouts, getAim } from '../api';

const Container = styled.div`
	flex: 1;
	height: 100%;
	display: flex;
	justify-content: center;
	padding: 22px 0px;
	overflow-y: scroll;
`;
const Wrapper = styled.div`
	flex: 1;
	max-width: 1400px;
	display: flex;
	flex-direction: column;
	gap: 22px;
	@media (max-width: 600px) {
		gap: 12px;
	}
`;
const Title = styled.div`
	padding: 0px 16px;
	font-size: 22px;
	color: ${({ theme }) => theme.text_primary};
	font-weight: 500;
`;

const FlexWrap = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	gap: 22px;
	padding: 0px 16px;
	@media (max-width: 600px) {
		gap: 12px;
	}
`;

const Section = styled.div`
	display: flex;
	flex-direction: column;
	padding: 0px 16px;
	gap: 22px;
	padding: 0px 16px;
	@media (max-width: 600px) {
		gap: 12px;
	}
`;
// const CardWrapper = styled.div`
// 	display: flex;
// 	flex-wrap: wrap;
// 	justify-content: center;
// 	gap: 20px;
// 	margin-bottom: 10px;
// 	@media (max-width: 600px) {
// 		gap: 12px;
// 	}
// `;

const Left = styled.div`
	flex: 0.2;
	height: fit-content;
	padding: 18px;
	border: 1px solid ${({ theme }) => theme.text_primary + 20};
	border-radius: 14px;
	box-shadow: 1px 6px 20px 0px ${({ theme }) => theme.primary + 15};
`;

const Right = styled.div`
	flex: 1;
`;

const CardWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	gap: 20px;
	margin-bottom: 20px;
	@media (max-width: 600px) {
		gap: 12px;
	}
`;

//   display: flex;
//   flex-direction: column;
//   padding: 0px 16px;
//   gap: 22px;
//   padding: 0px 16px;
//   @media (max-width: 600px) {
//     gap: 12px;
//   }
// `;
const SecTitle = styled.div`
	font-weight: 600;
	font-size: 16px;
	color: ${({ theme }) => theme.primary};
	@media (max-width: 600px) {
		font-size: 14px;
	}
`;

const LOW_CALORIE_PLAN = [
	{
		plan_name: 'Balanced Vegetarian Plan',
		meals: [
			{
				name: 'Breakfast',
				foods: [
					'Scrambled tofu with spinach, tomatoes, and mushrooms',
					'Whole grain toast',
					'Green tea or herbal tea',
				],
			},
			{
				name: 'Mid-Morning Snack',
				foods: ['Greek yogurt with sliced almonds and berries'],
			},
			{
				name: 'Lunch',
				foods: [
					'Quinoa salad with mixed vegetables (bell peppers, cucumbers, carrots, and avocado) dressed with lemon-tahini dressing',
				],
			},
			{
				name: 'Afternoon Snack',
				foods: [
					'Hummus with sliced vegetables (carrots, celery, bell peppers)',
				],
			},
			{
				name: 'Dinner',
				foods: [
					'Lentil soup with kale and sweet potatoes',
					'Grilled vegetable skewers (zucchini, eggplant, cherry tomatoes) with a side of brown rice',
				],
			},
			{
				name: 'Evening Snack (Optional)',
				foods: ['Air-popped popcorn seasoned with nutritional yeast'],
			},
		],
	},
	{
		plan_name: 'High-Protein Vegetarian Plan',
		meals: [
			{
				name: 'Breakfast',
				foods: [
					'Greek yogurt parfait with mixed berries and a sprinkle of chia seeds',
				],
			},
			{
				name: 'Mid-Morning Snack',
				foods: [
					'Protein smoothie made with almond milk, spinach, banana, and protein powder',
				],
			},
			{
				name: 'Lunch',
				foods: [
					'Chickpea salad with mixed greens, cucumbers, cherry tomatoes, and feta cheese, dressed with balsamic vinaigrette',
				],
			},
			{
				name: 'Afternoon Snack',
				foods: ['Cottage cheese with sliced pineapple'],
			},
			{
				name: 'Dinner',
				foods: [
					'Tofu stir-fry with broccoli, bell peppers, snap peas, and cashews, served over cauliflower rice',
				],
			},
			{
				name: 'Evening Snack (Optional)',
				foods: ['Roasted edamame sprinkled with sea salt'],
			},
		],
	},
	{
		plan_name: 'Low-Carb Vegetarian Plan',
		meals: [
			{
				name: 'Breakfast',
				foods: [
					'Veggie omelette with spinach, mushrooms, onions, and bell peppers',
				],
			},
			{
				name: 'Mid-Morning Snack',
				foods: [
					'Avocado slices with cherry tomatoes and a sprinkle of hemp seeds',
				],
			},
			{
				name: 'Lunch',
				foods: ['Zucchini noodles with marinara sauce and grilled tofu'],
			},
			{
				name: 'Afternoon Snack',
				foods: ['Almond butter on celery sticks'],
			},
			{
				name: 'Dinner',
				foods: [
					'Cauliflower crust pizza topped with tomato sauce, grilled vegetables (zucchini, eggplant, bell peppers), and vegan cheese',
				],
			},
			{
				name: 'Evening Snack (Optional)',
				foods: ['Sliced cucumber with tzatziki sauce'],
			},
		],
	},
];

const HIGH_CALORIE_PLAN = [
	{
		plan_name: 'High-Calorie Nutrition Plan 1',
		meals: [
			{
				name: 'Breakfast',
				foods: [
					'Scrambled eggs with cheese, spinach, and diced tomatoes',
					'Whole grain toast with avocado spread',
					'Glass of whole milk',
				],
			},
			{
				name: 'Mid-Morning Snack',
				foods: ['Greek yogurt with granola and mixed berries'],
			},
			{
				name: 'Lunch',
				foods: [
					'Turkey and cheese sandwich on whole wheat bread with lettuce, tomato, and mayonnaise',
					'Sweet potato fries',
					'Side salad with mixed greens, cucumbers, and balsamic vinaigrette',
				],
			},
			{
				name: 'Afternoon Snack',
				foods: [
					'Protein shake made with chocolate protein powder, banana, almond milk, and peanut butter',
				],
			},
			{
				name: 'Dinner',
				foods: [
					'Grilled salmon with quinoa and steamed broccoli',
					'Garlic sautéed green beans',
					'Whole grain roll with butter',
				],
			},
			{
				name: 'Evening Snack (Optional)',
				foods: [
					'Trail mix with mixed nuts, dried fruits, and dark chocolate chips',
				],
			},
		],
	},
	{
		plan_name: 'High-Calorie Nutrition Plan 2',
		meals: [
			{
				name: 'Breakfast',
				foods: [
					'Pancakes with maple syrup and butter',
					'Sausage links',
					'Orange juice',
				],
			},
			{
				name: 'Mid-Morning Snack',
				foods: [
					'Cottage cheese with pineapple chunks and a sprinkle of cinnamon',
				],
			},
			{
				name: 'Lunch',
				foods: [
					'Beef and vegetable stir-fry with rice noodles',
					'Steamed edamame',
					'Miso soup',
				],
			},
			{
				name: 'Afternoon Snack',
				foods: ['Whole grain crackers with cheese slices'],
			},
			{
				name: 'Dinner',
				foods: [
					'Baked chicken thighs with mashed potatoes and gravy',
					'Roasted carrots and parsnips',
					'Dinner roll with butter',
				],
			},
			{
				name: 'Evening Snack (Optional)',
				foods: ['Banana smoothie with protein powder, oats, and honey'],
			},
		],
	},
	{
		plan_name: 'High-Calorie Nutrition Plan 3',
		meals: [
			{
				name: 'Breakfast',
				foods: [
					'Bagel with cream cheese and smoked salmon',
					'Fruit salad with mango, pineapple, and kiwi',
				],
			},
			{
				name: 'Mid-Morning Snack',
				foods: ['Almond butter and banana sandwich on whole grain bread'],
			},
			{
				name: 'Lunch',
				foods: [
					'Vegetable and tofu curry with basmati rice',
					'Naan bread',
					'Cucumber raita',
				],
			},
			{
				name: 'Afternoon Snack',
				foods: ['Greek yogurt with honey and mixed nuts'],
			},
			{
				name: 'Dinner',
				foods: [
					'Pasta Alfredo with grilled chicken and broccoli',
					'Caesar salad with homemade dressing and croutons',
				],
			},
			{
				name: 'Evening Snack (Optional)',
				foods: ['Chocolate chip cookies with a glass of milk'],
			},
		],
	},
];

const Dashboard = () => {
	const [data, setData] = useState();
	const [sets, setSets] = useState('');
	const [weight, setWeight] = useState('');
	const [duration, setDuration] = useState('');
	const [workoutName, setWorkoutName] = useState('');
	const [repititions, setRepititions] = useState('');
	const [todaysWorkouts, setTodaysWorkouts] = useState([]);
	const [buttonLoading, setButtonLoading] = useState(false);
	const [workoutCategory, setWorkoutCategory] = useState('');

	const [loading, setLoading] = useState(false);
	const [date, setDate] = useState('');

	useEffect(() => {
		(async () => {
			setLoading(true);
			const token = localStorage.getItem('core-app-token');
			await getWorkouts(token, date ? `?date=${date}` : '').then((res) => {
				setTodaysWorkouts(res?.data?.todaysWorkouts);
				setLoading(false);
			});
		})();
	}, [date]);

	const workout = `#${workoutCategory}
  -${workoutName}
  -${sets} sets
  -${repititions} reps
  -${weight} kg
  -${duration} min`;

	const dashboardData = async () => {
		const token = localStorage.getItem('core-app-token');
		await getDashboardDetails(token).then((res) => {
			setData(res.data);
		});
	};

	const getTodaysWorkout = async () => {
		const token = localStorage.getItem('core-app-token');
		await getWorkouts(token, '').then((res) => {
			setTodaysWorkouts(res?.data?.todaysWorkouts);
		});
	};

	const addNewWorkout = async () => {
		setButtonLoading(true);
		const token = localStorage.getItem('core-app-token');
		await addWorkout(token, { workoutString: workout })
			.then(() => {
				dashboardData();
				getTodaysWorkout();
				setWorkoutCategory('');
				setWorkoutName('');
				setSets('');
				setRepititions('');
				setWeight('');
				setDuration('');
				setButtonLoading(false);
			})
			.catch((err) => {
				alert(err);
			});
	};

	const [aim, setAim] = useState();
	const getAimData = async () => {
		const token = localStorage.getItem('core-app-token');
		await getAim(token).then((res) => {
			setAim(res.data);
		});
	};

	useEffect(() => {
		getAimData();
		dashboardData();
		getTodaysWorkout();
	}, []);
	return (
		<Container>
			<Wrapper>
				<Title>
					Your aim is to&nbsp;
					<span style={{ color: 'green', fontWeight: 'bold' }}>{aim?.aim}</span>
					.
				</Title>
				<FlexWrap>
					{counts.map((item) => (
						<CountsCard item={item} data={data} />
					))}
				</FlexWrap>

				<FlexWrap>
					<CategoryChart data={data} />
					<WeeklyStatCard data={data} />
				</FlexWrap>

				<FlexWrap>
					<AddWorkout
						workoutCategory={workoutCategory}
						workoutName={workoutName}
						sets={sets}
						repititions={repititions}
						weight={weight}
						duration={duration}
						setWorkoutCategory={setWorkoutCategory}
						setWorkoutName={setWorkoutName}
						setSets={setSets}
						setRepititions={setRepititions}
						setWeight={setWeight}
						setDuration={setDuration}
						addNewWorkout={addNewWorkout}
						buttonLoading={buttonLoading}
					/>

					<Wrapper>
						<Left>
							<SecTitle>Select Date</SecTitle>
							<LocalizationProvider dateAdapter={AdapterDayjs}>
								<DateCalendar
									onChange={(e) => setDate(`${e.$M + 1}/${e.$D}/${e.$y}`)}
								/>
							</LocalizationProvider>
						</Left>
					</Wrapper>
				</FlexWrap>

				<Section>
					<Title>Workout <span style={{
						color: 'red',
						fontWeight: 'bold'
					
					}}>History</span></Title>

					<Wrapper>
						<Right>
							<Section>
								{loading ? (
									<CircularProgress />
								) : todaysWorkouts.length === 0 ? <h3>
									Oops ;( You didn't workout that day.
								</h3> :(
									<CardWrapper>
										{todaysWorkouts.map((workout) => (
											<WorkoutCard workout={workout} />
										))}
									</CardWrapper>
								)}
							</Section>
						</Right>
					</Wrapper>
				</Section>

				<Section>
					<Title>
						Nutrition Plans to&nbsp;
						<span style={{ color: 'green', fontWeight: 'bold' }}>
							{aim?.aim}
						</span>
						.
					</Title>

					<FlexWrap>
						{aim?.aim === 'lose weight'
							? LOW_CALORIE_PLAN.map((item) => <NutritionsCard item={item} />)
							: HIGH_CALORIE_PLAN.map((item) => <NutritionsCard item={item} />)}
					</FlexWrap>
				</Section>
				<br />
			</Wrapper>
		</Container>
	);
};

export default Dashboard;
