import React from "react";
import styled from "styled-components";

const Card = styled.div`
  flex: 1;
  min-width: 200px;
  padding: 24px;
  border: 1px solid ${({ theme }) => theme.text_primary + 20};
  border-radius: 14px;
  display: flex;
  gap: 6px;
  box-shadow: 1px 6px 20px 0px ${({ theme }) => theme.primary + 15};
`;

const Left = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 12px;
  @media (max-width: 600px) {
    gap: 6px;
  }
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 16px;
  color: ${({ theme }) => theme.primary};
  @media (max-width: 600px) {
    font-size: 14px;
  }
`;

const Span = styled.div`
  margin-bottom: 8px;
  font-weight: 500;
  font-size: 14px;
  @media (max-width: 600px) {
    font-size: 12px;
  }
  color: ${({ theme }) => theme.text_secondary + 90};
`;

const Desc = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.text_secondary + 90};
  margin-bottom: 6px;
  @media (max-width: 600px) {
    font-size: 12px;
  }
`;

const Em = styled.div`
  font-weight: 600;
  font-size: 14px;
  color: black;
  margin-top: 10px;
  margin-bottom: -16px;
  @media (max-width: 600px) {
    font-size: 12px;
  }
`;

const CountsCard = ({ item }) => {
  return (
    <Card>
      <Left>
        <Title>{item.plan_name}</Title>
        {item.meals.map((meal) => (
        <Desc>
          <Em>{meal.name}</Em>
          <br />
            {meal.foods.map((food) => (
              <Span>{food}</Span>
            ))}
        </Desc>
            ))}
      </Left>
    </Card>
  );
};

export default CountsCard;
