import React, { useState } from 'react';
import styled from 'styled-components';
import TextInput from './TextInput';
import Button from './Button';
import { UserSignUp } from '../api';
import { useDispatch } from 'react-redux';
import { loginSuccess } from '../redux/reducers/userSlice';

const Container = styled.div`
	width: 100%;
	max-width: 500px;
	display: flex;
	flex-direction: column;
	gap: 36px;
`;

const Title = styled.div`
	font-size: 30px;
	font-weight: 800;
	color: ${({ theme }) => theme.text_primary};
`;

const Span = styled.div`
	font-size: 16px;
	font-weight: 400;
	color: ${({ theme }) => theme.text_secondary + 90};
`;

const Select = styled.select`
	width: 100%;
	margin-top: -16px;
	padding: 10px;
	border-radius: 8px;
	border: 1px solid #ccc;
	outline: none;
	font-size: 16px;
	font-weight: 400;
	color: ${({ theme }) => theme.text_primary};
	background-color: ${({ theme }) => theme.background_secondary};
`;

const Label = styled.label`
	font-size: 12px;
	color: ${({ theme }) => theme.text_primary};
	padding: 0px 4px;
	${({ error, theme }) =>
		error &&
		`
    color: ${theme.red};
  `}
	${({ small }) =>
		small &&
		`
    font-size: 8px;
  `}
  ${({ popup, theme }) =>
		popup &&
		`
  color: ${theme.popup_text_secondary};
  `}
`;

const SignUp = () => {
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(false);
	const [buttonDisabled, setButtonDisabled] = useState(false);
	const [aim, setAim] = useState('lose weight');
	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [height, setHeight] = useState('');
	const [weight, setWeight] = useState('');
	const [password, setPassword] = useState('');

	const validateInputs = () => {
		if (!name || !email || !password) {
			alert('Please fill in all fields');
			return false;
		}
		return true;
	};

	const handleSignUp = async () => {
		setLoading(true);
		setButtonDisabled(true);
		if (validateInputs()) {
			await UserSignUp({ name, email, password, height, weight, aim })
				.then((res) => {
					dispatch(loginSuccess(res.data));
					alert('Account Created Success');
					setLoading(false);
					setButtonDisabled(false);
				})
				.catch((err) => {
					alert(err.response.data.message);
					setLoading(false);
					setButtonDisabled(false);
				});
		}
	};
	return (
		<Container>
			<div>
				<Title>Create New Account 👋</Title>
				<Span>Please enter details to create a new account</Span>
			</div>
			<div
				style={{
					display: 'flex',
					gap: '20px',
					flexDirection: 'column',
				}}
			>
				<TextInput
					label='Full name'
					placeholder='Enter your full name'
					value={name}
					handelChange={(e) => setName(e.target.value)}
				/>
				<TextInput
					label='Weight'
					placeholder='Enter your weight (kgs)'
					value={weight}
					handelChange={(e) => setWeight(e.target.value)}
				/>
				<TextInput
					label='Height'
					placeholder='Enter your weight (cms)'
					value={height}
					handelChange={(e) => setHeight(e.target.value)}
				/>

				<Label htmlFor='aim'>What's your aim?</Label>

				<Select value={aim} onChange={(e) => setAim(e.target.value)}>
					<option value='lose weight'>Lose weight</option>
					<option value='gain weight'>Gain weight</option>
				</Select>

				<TextInput
					label='Email Address'
					placeholder='Enter your email address'
					value={email}
					handelChange={(e) => setEmail(e.target.value)}
				/>
				<TextInput
					label='Password'
					placeholder='Enter your password'
					password
					value={password}
					handelChange={(e) => setPassword(e.target.value)}
				/>
				<Button
					text='Sign Up'
					onClick={handleSignUp}
					isLoading={loading}
					isDisabled={buttonDisabled}
				/>
			</div>
		</Container>
	);
};

export default SignUp;
