import React from 'react';
import styled from 'styled-components';
import TextInput from './TextInput';
import Button from './Button';

const Card = styled.div`
	flex: 1;
	min-width: 280px;
	padding: 24px;
	border: 1px solid ${({ theme }) => theme.text_primary + 20};
	border-radius: 14px;
	box-shadow: 1px 6px 20px 0px ${({ theme }) => theme.primary + 15};
	display: flex;
	flex-direction: column;
	gap: 6px;
	@media (max-width: 600px) {
		padding: 16px;
	}
`;
const Title = styled.div`
	font-weight: 600;
	font-size: 16px;
	color: ${({ theme }) => theme.primary};
	@media (max-width: 600px) {
		font-size: 14px;
	}
`;

const AddWorkout = ({
	workoutCategory,
	workoutName,
	sets,
	repititions,
	weight,
	duration,
  setWorkoutCategory,
  setWorkoutName,
  setSets,
  setRepititions,
  setWeight,
  setDuration,
	addNewWorkout,
	buttonLoading,
}) => {
	return (
		<Card>
			<Title>Add New Workout</Title>
			<TextInput
				label='Category'
				placeholder={`Workout Category (eg. Legs)`}
				value={workoutCategory}
				handelChange={(e) => setWorkoutCategory(e.target.value)}
			/>
			<TextInput
				label='Workout Name'
				placeholder={`Workout Name (eg. Back Squat)`}
				value={workoutName}
				handelChange={(e) => setWorkoutName(e.target.value)}
			/>
			<TextInput
				label='Sets'
				placeholder={`Number of Sets (eg. 5)`}
				value={sets}
				handelChange={(e) => setSets(e.target.value)}
			/>
			<TextInput
				label='Repititions'
				placeholder={`Number of Repititions (eg. 15)`}
				value={repititions}
				handelChange={(e) => setRepititions(e.target.value)}
			/>
			<TextInput
				label='Weight'
				placeholder={`Weight (eg. 30)`}
				value={weight}
				handelChange={(e) => setWeight(e.target.value)}
			/>
			<TextInput
				label='Workout Duration'
				placeholder={`Duration (eg. 10)`}
				value={duration}
				handelChange={(e) => setDuration(e.target.value)}
			/>

			<Button
				text='Add Workout'
				small
				onClick={() => addNewWorkout()}
				isLoading={buttonLoading}
				isDisabled={buttonLoading}
			/>
		</Card>
	);
};

export default AddWorkout;
